<template>
  <div class="package-skeleton">
    <div class="image-text-holder">
      <div class="image ratio ratio-4_3">
        <div class="body skeleton_loader"></div>
      </div>
      <div class="text-holder">
        <div class="skeleton w-100">
          <div class="line skeleton_loader dark medium w-80"></div>
        </div>
        <div class="skeleton">
          <div class="line skeleton_loader dark thick w-50px"></div>
        </div>
      </div>
    </div>
    <div class="text">
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-50"></div>
      </div>
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-70"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageCardSkeleton",
};
</script>

<style lang="scss" scoped>
.package-skeleton {
  display: flex;
  flex-direction: column;
  gap: map-get($spacings, "small");
  .image-text-holder {
    display: flex;
    gap: map-get($spacings, "small");
    .text-holder {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: map-get($spacings, "small");
    }
  }
}
</style>
