<template>
  <div class="container">
    <confirm-modal
      ref="modalAgreement"
      modalDialogClasses="modal-notice"
      :modalWrapper="null"
      :title="$t('packages.agreement.title')"
      :body="$t('packages.agreement.body')"
      :confirmBtn="$t('packages.agreement.button')"
      confirmBtnClass="btn btn-primary"
      declinedBtnClass="btn-default"
      :closeButton="false"
      :checkBoxText="$t('packages.agreement.checkBoxText')"
      @confirmed="acceptAgreement"
    >
      <button ref="packageAgreement" class="d-none"></button>
    </confirm-modal>
    <div class="packages-index">
      <div class="text-wrapper font-size-xl d-flex">
        <div class="text">{{ $t("packages.index.title") }}</div>
        <div v-if="meta?.total > 0" class="number">
          {{ meta.total }}
        </div>
      </div>
      <div class="packages">
        <package-card-skeleton v-if="!package_ready"> </package-card-skeleton>
        <package-card-skeleton v-if="!package_ready"> </package-card-skeleton>
        <package-card-skeleton v-if="!package_ready"> </package-card-skeleton>
        <package-card-skeleton v-if="!package_ready"> </package-card-skeleton>
        <router-link
          v-for="single_package in packages"
          :key="single_package.id"
          class="router"
          :to="'/packages/' + single_package.id + '?title=' + single_package.name"
        >
          <div class="package-card">
            <package-template class="pb-3 border-bottom-color">
              <PackageColor :color="single_package.name" />
              <package-card-content>
                <PackageBadge :enabled="single_package.status" />
                <div class="package-activity">
                  <div class="package-name font-size-big dotdotdot lines-2">
                    {{ single_package.name }}
                  </div>
                </div>
              </package-card-content>
              <package-icon><div class="icon"></div> </package-icon>
            </package-template>
            <div class="package-products">
              <div class="products font-size-md">
                {{ $t("packages.index.products") }}
                {{ single_package.products.length }}
              </div>
              <div class="shop">
                {{ single_package.shop.name }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <loading :loading="!loading_ready" />
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import PackageCardContent from "@/components/Packages/parts/PackageCardContent.vue";
import PackageColor from "@/components/Packages/parts/PackageColor.vue";
import PackageTemplate from "@/components/Packages/parts/PackageTemplate.vue";
import PackageBadge from "@/components/Packages/parts/PackageBadge.vue";
import PackageIcon from "@/components/Packages/parts/PackageIcon.vue";
import Loading from "@/components/Loading.vue";
import PackageCardSkeleton from "@/views/Packages/partials/PackageCardSkeleton.vue";
import packageAgreement from "@/mixins/package-agreement";
import { mapActions } from "vuex";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  components: {
    ConfirmModal,
    PackageTemplate,
    PackageColor,
    PackageCardContent,
    PackageBadge,
    PackageIcon,
    Loading,
    PackageCardSkeleton,
  },
  name: "PackagesIndex",
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("packages.page_title"),
    })
  },
  mixins: [packageAgreement],
  mounted() {
    this.checkAgreement();
    document.addEventListener("scroll", this.paginate);
  },
  data() {
    return {
      showModal: false,
      packages: {},
      meta: {},
      page: 1,
      loading_ready: true,
      package_ready: false,
    };
  },
  methods: {
    ...mapActions(["setErrors"]),
    packageIndex() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("packages_index");

        window.axios
          .get(url, {
            params: {
              pagination: 12,
              page: this.page,
              orderBy: "desc",
            },
          })
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
          );
      });
    },
    paginate() {
      if (this.packages?.length < this.meta?.total) {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
          this.page = this.page + 1;
          this.loading_ready = false;
          this.packageIndex().then(
            (response) => {
              this.loading_ready = true;
              this.meta = response.meta;
              this.packages = [...this.packages, ...response.data];
            },
            () => {
              //Redirect to 404
              this.$router.replace("/404");
            }
          );
        }
      }
    },
  },
  watch: {
    packageGet: {
      immediate: true,
      handler() {
        this.packageIndex().then(
          (response) => {
            this.meta = response.meta;
            this.packages = response.data;
            this.package_ready = true;
          },
          () => {
            //Redirect to 404
            this.$router.replace("/404");
          }
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;

  .packages-index {
    // min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: map-get($spacings, "medium");
    @include media-breakpoint-down(lg) {
      gap: map-get($spacings, "small");
    }
    padding-top: map-get($spacings, "xl");
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
    @media (max-width: 360px) {
      padding-top: 25%;
    }

    .text-wrapper {
      gap: map-get($spacings, "medium");
      .text {
        font-weight: bold;
      }
      .number {
        color: map-get($colors, "twiks");
      }
    }
    .packages {
      gap: map-get($spacings, "medium");
      padding-bottom: map-get($spacings, "medium");
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
      }
      @include media-breakpoint-down(lg) {
        gap: map-get($spacings, "small");
      }
      .package-card {
        display: flex;
        flex-direction: column;
        padding: map-get($spacings, "medium");
        border: 1px solid map-get($colors, "bordering-gray");
        border-radius: 8px;
        height: 100%;
        @include media-breakpoint-down(lg) {
          align-items: center;
          padding: map-get($spacings, "small");
        }
        gap: map-get($spacings, "small");
        .package-name {
          word-break: break-word;
          font-weight: bold;
          letter-spacing: 0px;
        }
        .icon {
          width: 10px;
          height: 10px;
          background-image: url("../../assets/nav_arrow_right.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
        .border-bottom-color {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgb(213, 213, 213, 0.4);
        }
        .package-products {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          .products {
            color: map-get($colors, "black");
          }
          .shop {
            color: map-get($colors, "secondary");
          }
        }
      }
    }
  }
}
</style>
